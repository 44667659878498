var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "knowledge-base-article" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/5 lg:w-1/4" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.RelatedArticles.i18n) ||
                  _vm.resources.RelatedArticles.name
              }
            },
            [
              _c(
                "ul",
                { staticClass: "bordered-items" },
                _vm._l(_vm.relatedList, function(idx) {
                  return _c("li", { key: idx.id, staticClass: "py-2" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.get(idx.id, idx.contentTypeId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(idx.title))]
                    )
                  ])
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" },
        [
          _c("vx-card", [
            _c("div", { staticClass: "article-title mb-6" }, [
              _c("h1", [_vm._v(_vm._s(_vm.article.title))]),
              _c("p", { staticClass: "mt-1 mb-4" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(_vm.resources.CreationDate.i18n) ||
                        _vm.resources.CreationDate.name
                    ) +
                    ":\n            "
                ),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("date")(_vm.article.creationDate, true)))
                ])
              ])
            ]),
            _vm.article.attachmentType == "MP4"
              ? _c("iframe", {
                  staticClass: "mb-base",
                  attrs: {
                    src: _vm.article.attachementUrl,
                    width: "600",
                    height: "400",
                    frameborder: "0",
                    allow:
                      "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                    allowfullscreen: ""
                  }
                })
              : _c("img", {
                  staticClass: "my-3 rounded",
                  attrs: {
                    src: _vm.article.attachementUrl,
                    alt: "article-img",
                    width: "500"
                  }
                }),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.article.body) } })
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }