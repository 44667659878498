<template>
  <div id="knowledge-base-article">
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4">
        <vx-card
          :title="
            $t(resources.RelatedArticles.i18n) || resources.RelatedArticles.name
          "
        >
          <ul class="bordered-items">
            <li v-for="idx in relatedList" :key="idx.id" class="py-2">
              <a @click="get(idx.id, idx.contentTypeId)">{{
                idx.title
              }}</a>
            </li>
          </ul>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vx-card>
          <!-- Title -->
          <div class="article-title mb-6">
            <h1>{{ article.title }}</h1>
            <p class="mt-1 mb-4">
              {{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }}:
              <span>{{ article.creationDate | date(true) }}</span>
            </p>
          </div>

          <iframe
            v-if="article.attachmentType == 'MP4'"
            :src="article.attachementUrl"
            width="600"
            height="400"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="mb-base"
          ></iframe>
          <img
            v-else
            :src="article.attachementUrl"
            alt="article-img"
            width="500"
            class="my-3 rounded"
          />

          <div v-html="article.body"></div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      relatedList: [],
      id: 0,
      article: {}
    };
  },
  async created() {
    this.$vs.loading();

    this.id = this.$route.params.id;
    this.contentTypeId = this.$route.params.contentTypeId;

    if (this.id !== null && this.id !== 0)
      await this.get(this.id, this.contentTypeId);

    this.$vs.loading.close();
  },
  methods: {
    async get(id, contentTypeId) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: 2,
            contentTypeId: parseInt(contentTypeId)
          },
          page: 1,
          rows: 100,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.article = {};
            this.relatedList = [];
          } else {
            this.relatedList = result.data.data;
            this.article = result.data.data.find(x => x.id == id);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.$emit("changeRouteTitle", "Question");
  }
};
</script>
